import {
  staticPath_assets_illust_goal_after_png,
  staticPath_assets_illust_toshin_desc_3_png,
  staticPath_assets_illust_concierge_three_contents_2_svg,
} from "lib/$asset-path"
import { ChevronRightIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  chakra,
  HTMLChakraProps,
  SimpleGrid,
  StackProps,
  VStack,
} from "@chakra-ui/react"
import {
  NextImage,
  NextImageInlineWrapper,
  NextImageProps,
} from "components/system"
import { pagesPath } from "lib/$path"

import NextLink from "next/link"
import React from "react"

export type WhatYouCanDoProps = {
  titleProps?: TitleProps
} & StackProps

/** マネイロでできること */
export const WhatYouCanDo = ({ titleProps, ...props }: WhatYouCanDoProps) => (
  <VStack
    id="what-you-can-do"
    spacing={4}
    bgColor="moneiro-color-main-orange"
    w="full"
    maxW="container.content"
    px={{ base: 3, md: 8 }}
    py={4}
    borderRadius={{ base: undefined, md: "m" }}
    {...props}
  >
    <Title {...titleProps} />
    <SimpleGrid
      columns={{ base: 1, md: 3 }}
      spacing={4}
      maxW="container.content"
      w="full"
      justifyItems="center"
    >
      {DATA.map((data, idx) => {
        return <Card {...data} key={idx} />
      })}
    </SimpleGrid>
  </VStack>
)

type TitleProps = {
  mainText?: string
  mainStyle?: HTMLChakraProps<"h2">
  subText?: string
  subStyle?: HTMLChakraProps<"div">
}

const Title = ({
  mainText = "お金の診断・勉強・相談まで対応！",
  mainStyle,
  subText = "マネイロでできること",
  subStyle,
}: TitleProps) => {
  return (
    <chakra.h2
      textStyle={{ base: "18-bold", md: "24-bold" }}
      textAlign="center"
      color="white"
      {...mainStyle}
    >
      <chakra.div textStyle={{ base: "12-bold", md: "13-bold" }} {...subStyle}>
        {mainText}
      </chakra.div>
      {subText}
    </chakra.h2>
  )
}

const Card = ({ imgSrc, imgW, imgH, title, btnHref, btnText }: Data) => (
  <VStack
    spacing={2}
    justify="space-between"
    bgColor="white"
    w="full"
    maxW={{ base: "full", md: "235px" }}
    px={2}
    py={6}
    borderRadius="m"
  >
    <NextImageInlineWrapper w={imgW} h={imgH} pos="relative">
      <NextImage src={imgSrc} layout="fill" objectFit="contain" alt="" />
    </NextImageInlineWrapper>
    <VStack spacing={2} w="full">
      <chakra.p
        textStyle={{ base: "18-bold", md: "14-bold" }}
        color="moneiro-color-main-orange"
        textAlign="center"
      >
        {title}
      </chakra.p>
      <NextLink href={btnHref} passHref legacyBehavior>
        <Button
          as="a"
          colorScheme="secondary"
          justifyContent="space-between"
          fontSize={{ base: "16px", md: "14px" }}
          leftIcon={<Box boxSize={{ base: "24px", md: 0 }} />}
          rightIcon={<ChevronRightIcon boxSize="24px" />}
          w="full"
          h={{ base: "50px", md: "35px" }}
        >
          {btnText}
        </Button>
      </NextLink>
    </VStack>
  </VStack>
)

type Data = {
  imgSrc: string
  imgW: NextImageProps["w"]
  imgH: NextImageProps["h"]
  title: React.ReactNode
  btnText: string
  btnHref: string
}

const DATA: Data[] = [
  {
    imgSrc: staticPath_assets_illust_goal_after_png,
    imgW: { base: 315, md: 205 },
    imgH: { base: 190, md: 145 },
    title: (
      <>
        3分投資診断で
        <br />
        あなたに役立つ投資がわかる！
      </>
    ),
    btnText: "3分投資診断を始める",
    btnHref: pagesPath.simulation.$3min.$url().pathname,
  },
  {
    imgSrc: staticPath_assets_illust_toshin_desc_3_png,
    imgW: { base: 215, md: 185 },
    imgH: { base: 190, md: 125 },
    title: (
      <>
        無料オンラインセミナーで
        <br />
        お金の基礎知識が身につく！
      </>
    ),
    btnText: "おすすめセミナーを見る",
    btnHref: pagesPath.money_seminar.$url().pathname,
  },
  {
    imgSrc: staticPath_assets_illust_concierge_three_contents_2_svg,
    imgW: { base: 315, md: 205 },
    imgH: { base: 190, md: 125 },
    title: (
      <>
        プロが継続的にサポート！
        <br />
        無料で何度も相談できる
      </>
    ),
    btnText: "マネー相談会",
    btnHref: pagesPath.consulting.$url().pathname,
  },
]

import { ChevronLeftIcon } from "@chakra-ui/icons"
import { Box, Button, Center, Stack, StackProps } from "@chakra-ui/react"
import { Layer } from "components/lp/Layer"
import { Corporate } from "components/lp/widgets/Corporate"
import { WhatYouCanDo } from "components/lp/widgets/WhatYouCanDo"
import { pagesPath } from "lib/$path"
import NextLink from "next/link"
import LazyHydrate from "react-lazy-hydration"
import { LineBanner } from "../LineBanner"

export type InfoSectionProps = {
  hideLinkToTopButton?: boolean
} & StackProps

export const InfoSection = ({
  hideLinkToTopButton,
  ...props
}: InfoSectionProps) => {
  return (
    <LazyHydrate whenIdle>
      <Stack
        spacing={{ base: 6, md: 10 }}
        mt={{ base: 6, md: 10 }}
        mb={12}
        {...props}
      >
        {!hideLinkToTopButton && (
          <NextLink href={pagesPath.media.$url()} passHref legacyBehavior>
            <Button
              as="a"
              size="lg"
              w="full"
              maxW="315px"
              variant="outline"
              justifyContent="space-between"
              leftIcon={<ChevronLeftIcon boxSize="24px" />}
              rightIcon={<Box boxSize="24px" />}
              mx="auto"
              _hover={{
                bg: "moneiro-color-line-gray-basic",
              }}
            >
              マネイロメディアTOPへ
            </Button>
          </NextLink>
        )}
        <Center as="section">
          <WhatYouCanDo />
        </Center>
        <Center as="section" px={{ base: 3, md: 0 }}>
          <LineBanner />
        </Center>
        <Layer
          titleLabel="運営会社"
          id="corporate"
          isFullWidth
          maxW="container.content"
          pt={20}
          variant="condenced"
        >
          <Corporate />
        </Layer>
      </Stack>
    </LazyHydrate>
  )
}
